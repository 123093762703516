<template>
    <div class="body">
        <div class="concol contentbody" v-if="content.value">
          <div class="title" v-if="content.value.en_title">{{content.value.en_title}}</div>
          <div class="content" v-html="content.value.en_content"></div>
          <div class="btn">
            <div class="tip">
                <el-checkbox v-model="checked" size="large" >
                  <span>I have read the above, understand and agree to accept the terms and conditions.</span>
                </el-checkbox>
            </div>
            <button @click="btnclick">Next</button>
          </div>
        </div>
    </div>
</template>
<script>
  import { defineComponent, ref, computed, watch, reactive } from "vue";
  import { useGetters } from "@/store/use.js";
  import { useRouter, onBeforeRouteUpdate } from "vue-router";
  import store from "@/store";
  import { useI18n } from "vue-i18n";
  import nodata from "@/pages/nodata.vue";
import { ElMessageBox, ElMessage } from 'element-plus'
import { Ajax, MessageTips } from '@/utils'
  export default defineComponent({
    name: "评委守则",
    components: {
      nodata,
    },
    setup() {
      const { t, locale } = useI18n();
      const router = useRouter();
      const getters = useGetters(["language",]);
      const checked=ref(false);
      const language = getters.language;
      const content=reactive({})
      store.dispatch('app/getJudges_rules').then(res=>{
        content.value=res.judge_rule;
      });
      const btnclick = () => {
          console.log(checked.value)
        if(!checked.value){
             ElMessage({
                    message: 'Warning, this is a warning message.',
                    type: 'warning',
                })
        }else{
          Ajax.POST({
            url:'/api/judges/agreeRules',
            data:{judges_id:sessionStorage.getItem('judges_id')}
          }).then(res=>{
            if(res.data.code===10000){
               sessionStorage.setItem('is_agree_rule',1)
               router.push('/review-index')
            }
            console.log(res)
          })
        }
      };
      
      return {
        language,
        btnclick,
        checked,
        content
      };
    },
  });
</script>
<style lang="scss" scoped>
  .body {
    background: #f6f6f6;
    width: 100%;
   font-family: PingFangSC-Regular, PingFang SC;
    .concol{
      padding: 40px;
      margin-left: 6px;
      background: #fff;
      min-height: calc(100vh - 359px);
    color: #333333;
      margin: 20px auto 50px;
      .content {
        line-height: 28px;
        font-size: 16px;
        font-weight: 400;
        min-height: 144px;
      }
    }
    .title {
      font-size: 30px;
      text-align: center;
      font-weight: 600;
      margin-bottom: 50px;
      line-height: 42px;
      margin-top: 50px;
    }

    :deep(.btn) {
      margin: 80px 0 22px;
      text-align: center;
      .el-checkbox__inner{
        width: 12px;
        height: 12px;
        // background: #E34C3E;
        border-radius: 2px;
      }
      span{
        font-size: 14px;
        font-weight: 400;
        color: #9B9B9B;
        line-height: 20px;
        margin-left: 10px;
      }
      button {
        border: 1px solid #E34C3E;
        color: #E34C3E;
        background: #fff;
        font-size: 18px;
        width: 150px;
        height: 50px;
        margin-top: 20px;
        cursor: pointer;
      }

      button:hover {
        background: #E34C3E;
         color: #fff;
      }
    }
  }

  @media screen and (min-width: 375px) and (max-width: 750px) {
    .contentbody {
      width: 95%;
      .menucol{display: none;}
      .concol{max-width: 100%;flex:100%}
    }
  }
</style>